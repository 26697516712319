<template>
  <div :class="$style.container">
    <form :class="$style.form" @submit.prevent="submitForm">
      <div :class="$style.top">
        <h1 :class="$style.title">Вход</h1>
        <Input
          v-model="form.email"
          :class="$style.email"
          :error="errors.email"
          placeholder="Ваш e-mail"
        />
        <Input
          v-model="form.password"
          type="password"
          :error="errors.password"
          placeholder="Пароль"
        />
      </div>
      <div :class="$style.bottom">
        <Button type="submit">войти</Button>
        <div v-if="error" :class="$style.error">
          {{ error }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Input from '@/components/atoms/Input.vue'
import Button from '@/components/atoms/Button.vue'
import regexp from '@/helpers/regexp.js'

export default {
  components: {
    Input,
    Button,
  },
  data() {
    return {
      error: '',
      form: {
        email: '',
        password: '',
      },
      errors: {
        email: '',
        password: '',
      },
    }
  },
  methods: {
    resetForm() {
      this.form.email = ''
      this.form.password = ''
    },
    clearErros() {
      this.error = ''
      for (const key in this.errors) {
        this.errors[key] = ''
      }
    },
    isFormValid() {
      let isValid = true
      if (!this.form.email) {
        isValid = false
        this.errors.email = 'заполните это поле'
      } else if (!regexp.email.test(this.form.email)) {
        isValid = false
        this.errors.email = 'некорректный e-mail'
      }
      if (!this.form.password) {
        isValid = false
        this.errors.password = 'заполните это поле'
      }
      return isValid
    },
    async submitForm() {
      this.clearErros()

      if (this.isFormValid()) {
        const result = await this.$store.dispatch('login', this.form)
        if (result.error) {
          this.error = result.error
        } else {
          this.resetForm()
          this.$router.push('/')
        }
      }
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/breakpoints.scss';

.container {
  @include container;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  @include tablet {
    flex-direction: column;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .form {
    @include card;
    width: 38.5rem;
    max-height: 31.25rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include laptop {
      width: 26rem;
    }
    @include tablet {
      width: 100%;
    }
    .top {
      margin-bottom: 1.5rem;
      .title {
        margin-bottom: 1.5rem;
      }
      .email {
        margin-bottom: 1.5rem;
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      .error {
        @include errorInscription;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
